import { css } from '@emotion/react'
import { memo } from 'react'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Bar,
  BarChart,
  Cell,
} from 'recharts-new'
import { DailyProfit } from '~/modules/tradeSheet/tradingAnalyzer/useTradingSummaryHashResource'
import dayAPI from '~/utils/dayAPI'
import { representationAsNTD } from '~/utils/representationAsNTD'
import { toReadableUnitChinese } from '~/utils/representChineseNumberFormat'

const DailyPerformanceChart = memo<
  ReactProps<{
    data: DailyProfit[]
  }>
>(function DailyPerformanceChart({ data }) {
  const CustomizedXLabel = memo<
    React.PropsWithChildren<{
      tickProps: { x: number; y: number; payload: { value: string } }
    }>
  >(function CustomizedXLabel({ tickProps }) {
    const { x, y, payload } = tickProps
    const origin = data.find(datum => datum.datetime === payload.value)
    return (
      <svg>
        <text
          textAnchor='middle'
          x={x}
          y={y + 4}
          fontSize={14}
        >
          {origin && dayAPI(origin.datetime).format('MM/DD')}
        </text>
      </svg>
    )
  })
  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
    >
      <BarChart data={data}>
        <XAxis
          dataKey={'datetime'}
          tick={p => <CustomizedXLabel tickProps={p} />}
        />
        <YAxis
          tickLine={false}
          tick={p => <CustomizedYLabel tickProps={p} />}
        />
        <Tooltip content={<CustomizedTooltip />} />

        <CartesianGrid
          vertical={false}
          strokeDasharray='2 2'
        />
        <Bar dataKey='profit'>
          {data.map((datum, index) => (
            <Cell
              key={index}
              fill={datum.profit <= 0 ? '#11aa11' : '#cc2222'}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
})

const CustomizedTooltip = ({ payload }: { payload?: { payload: DailyProfit }[] }) => {
  if (!payload || (payload && payload.length < 1)) return null
  const datum = payload[0].payload

  return (
    <div
      css={css`
        background: #ffffff;
        padding: 0 8px;
        border-radius: 8px;
        border: #a0a0a0 1px solid;
      `}
    >
      <p>{dayAPI(datum.datetime).format('YYYY/MM/DD')}</p>

      <p>當日： {representationAsNTD(datum.profit)}</p>
    </div>
  )
}

const CustomizedYLabel = memo<
  React.PropsWithChildren<{
    tickProps: { x: number; y: number; payload: { value: number }; index: number }
  }>
>(function CustomizedYLabel({ tickProps }) {
  const { x, y, payload } = tickProps
  return (
    <svg>
      <text
        fontSize={14}
        x={x - 24}
        y={y + 3}
      >
        {toReadableUnitChinese(payload.value)}
      </text>
    </svg>
  )
})

export default DailyPerformanceChart

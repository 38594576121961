import { css } from '@emotion/react'
import { memo } from 'react'
import useMedia from '~/hooks/useMedia'
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss'
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar'
import { store } from '~/pages/heineken_template/_private/store'

export const David0705_Topbar = memo<ReactProps>(function David0705_Topbar() {
  const { isPc } = useMedia()
  return (
    <div
      css={css`
        ${fill_horizontal_cross_center};
        justify-content: end;
        background-color: #33394b88;
      `}
    >
      {isPc && <ChartingServerSwitch charting={store.charting} />}
      {isPc && <UserAvatarAsDialogButton />}
      {!isPc && (
        <Preset_Topbar
          showLeftBurger
          showRightBurger
          componentsInRight={
            <div
              css={css`
                ${fill_horizontal_cross_center};
                justify-content: end;
                background-color: #33394b88;
              `}
            >
              <ChartingServerSwitch charting={store.charting} />
              <UserAvatarAsDialogButton />
            </div>
          }
        ></Preset_Topbar>
      )}
    </div>
  )
})

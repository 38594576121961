import axios from 'axios-0-27-2'
import useSWR from 'swr-0-5-6'
import { apirc } from '~/configs/apirc'

const WIP_ROOT_URL = apirc.unclassified.tradingSummaryUploadVersion + '/api/v2/tradingSummary'

export type DailyProfit = {
  datetime: string
  profit: number
}
type DailyAccumulatedProfit = DailyProfit

type CommodityProportionData = {
  commodity: string
  proportion: number
}

type FrequencyData = {
  tradingLotFrequency: number
  tradingNumFrequency: number
}

type Top3PnlItem = {
  totalProfit: number
  commodity: string
}
export type Top3Pnl = {
  profitStock1: Top3PnlItem | undefined
  profitStock2: Top3PnlItem | undefined
  profitStock3: Top3PnlItem | undefined
  lossStock1: Top3PnlItem | undefined
  lossStock2: Top3PnlItem | undefined
  lossStock3: Top3PnlItem | undefined
}

export type WinRateData = { winRate: number }
export type ProfitFactorData = { profitFactor: number }
export type ProfitFactorPerLotData = { profitFactor: number }
export type MaxDrawdownData = { maxDrawdown: number }
export type ProfitData = { profit: number }

const defaultConfig = { shouldRetryOnError: true, errorRetryCount: 0, revalidateOnFocus: false }

/** 勝率 */
export const useWinRate = (hashString: string, since?: string) => {
  const res = useSWR<WinRateData>(
    hashString ? `${WIP_ROOT_URL}/winRate?hashString=${hashString}&sinceWhen=${since}` : null,
    fetcherWithToken,
    defaultConfig,
  )

  return res.data
}

/** 每口賺賠比 */
export const useProfitFactorPerLot = (hashString: string, since?: string) => {
  const res = useSWR<ProfitFactorPerLotData>(
    hashString
      ? `${WIP_ROOT_URL}/profitFactorPerLot?hashString=${hashString}&sinceWhen=${since}`
      : null,
    fetcherWithToken,
    defaultConfig,
  )
  return res.data
}

/** 賺賠比 */
export const useProfitFactor = (hashString: string, since?: string) => {
  const res = useSWR<ProfitFactorData>(
    hashString ? `${WIP_ROOT_URL}/profitFactor?hashString=${hashString}&sinceWhen=${since}` : null,
    fetcherWithToken,
    defaultConfig,
  )

  return res.data
}

/** 最大回測 */
export const useMaxDrawdown = (hashString: string, since?: string) => {
  const res = useSWR<MaxDrawdownData>(
    hashString ? `${WIP_ROOT_URL}/maxDrawdown?hashString=${hashString}&sinceWhen=${since}` : null,
    fetcherWithToken,
    defaultConfig,
  )

  return res.data
}

/** 交易商品比重：單位為口數 */
export const useCommodityProportion = (hashString: string, since?: string) => {
  const res = useSWR<CommodityProportionData[]>(
    hashString
      ? `${WIP_ROOT_URL}/commodityProportion?hashString=${hashString}&sinceWhen=${since}`
      : null,
    fetcherWithToken,
    defaultConfig,
  )

  return res.data ?? []
}

/** 交易頻率 */
export const useFrequency = (hashString: string, since?: string) => {
  const res = useSWR<FrequencyData>(
    hashString ? `${WIP_ROOT_URL}/frequency?hashString=${hashString}&sinceWhen=${since}` : null,
    fetcherWithToken,
    defaultConfig,
  )

  return res.data
}

/** 每日報酬[] */
export const useDailyProfit = (hashString: string, since?: string) => {
  const res = useSWR<{ dailyProfit: DailyProfit[] }>(
    hashString ? `${WIP_ROOT_URL}/dailyProfit?hashString=${hashString}&sinceWhen=${since}` : null,
    fetcherWithToken,
    defaultConfig,
  )

  return res.data
}

/** 整體報酬 */
export const useProfit = (hashString: string, since?: string) => {
  const res = useSWR<ProfitData>(
    hashString ? `${WIP_ROOT_URL}/profit?hashString=${hashString}&sinceWhen=${since}` : null,
    fetcherWithToken,
    defaultConfig,
  )

  return res.data
}

/** 每日累積報酬[] */
export const useDailyAccumulatedProfit = (hashString: string, since?: string) => {
  const res = useSWR<{ dailyProfit: DailyAccumulatedProfit[] }>(
    hashString
      ? `${WIP_ROOT_URL}/dailyAccumulatedProfit?hashString=${hashString}&sinceWhen=${since}`
      : null,
    fetcherWithToken,
    defaultConfig,
  )

  return res.data
}

/** 前三獲利及虧損商品[] */
export const useTop3Pnl = (hashString: string, since?: string) => {
  const res = useSWR<Top3Pnl>(
    hashString ? `${WIP_ROOT_URL}/Top3Pnl?hashString=${hashString}&sinceWhen=${since}` : null,
    fetcherWithToken,
    defaultConfig,
  )

  return res.data
}

export const fetcherWithToken = async (url: string, token: string) => {
  return fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(res => {
    if (res.status !== 200) {
      return null
    }

    return res.json()
  })
}

export const uploadCsv = async (file: File) => {
  const data = new FormData()
  data.append('file', file)
  return axios
    .post<{ hashString: string }>(WIP_ROOT_URL + `/upload`, data, {
      headers: {
        Accept: 'application/json',
        ContentType: 'multipart/form-data',
      },
    })
    .then(res => res.data)
}

import { createStore } from '~/store/createStore'

// N1M, N3M, NHY, N1Y, NTY
export enum Time {
  'N1M' = '近一月',
  'N3M' = '近三月',
  'NHY' = '近半年',
  'N1Y' = '近一年',
  'NTY' = '今年',
}

export type TimeKey = keyof typeof Time

export const useTimeRangeStore = createStore<{
  since: TimeKey
  readonly changeTimeRange: (timeKey: TimeKey) => void
}>((set, get) => {
  return {
    since: 'NHY',
    changeTimeRange(time: TimeKey) {
      set(state => {
        state.since = time
      })
    },
  }
})

import { Dayjs } from 'dayjs'
import { apirc } from '~/configs/apirc'
import { fetchDate } from './dateUtils'

export type AmplitudeTradeInfo = {
  AvgCumulativeAmp: number
  AvgCurrentAmp: number
  CumulativeAmp: number
  CurrentAmp: number
  Progress: number
  /** E.g. `'12:00'` */
  Time: string
}

export type VolumeTradeInfo = {
  AvgCumulativeVol: number
  AvgCurrentVol: number
  CumulativeVol: number
  CurrentVol: number
  Progress: number
  /** E.g. `'12:00'` */
  Time: string
}

export const fetchVolResult = async (startDate: Dayjs) => {
  return fetch(
    `${apirc.unclassified.tradeInfoURL}?type=vol&date=` + fetchDate(startDate).format('YYYY-MM-DD'),
  ).then(
    response =>
      response.json() as unknown as
        | VolumeTradeInfo
        | {
            status: 'no data'
          },
  )
}

export const fetchAmpResult = async (startDate: Dayjs) => {
  return fetch(
    `${apirc.unclassified.tradeInfoURL}?type=amp&date=` +
      fetchDate(startDate).format('YYYY-MM-DD').toString(),
  ).then(
    response =>
      response.json() as unknown as
        | AmplitudeTradeInfo
        | {
            status: 'no data'
          },
  )
}

import { memo } from 'react'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { useTradingSummaryHashState } from '~/modules/tradeSheet/tradingAnalyzer/useTradingSummaryHashState'
import { tradeFreqValueCss } from '~/modules/tradeSheet/tradingAnalyzer/tradingAnalyzerCss'

const TradeFrequencyLot = memo(function TradeFrequency() {
  const { state } = useTradingSummaryHashState.useContainer()

  return (
    <div css={flex.h.crossCenter}>
      <div css={tradeFreqValueCss}>{state.frequency?.tradingLotFrequency}</div>
    </div>
  )
})

const TradeFrequencyNum = memo(function TradeFrequency() {
  const { state } = useTradingSummaryHashState.useContainer()

  return (
    <div css={flex.h.crossCenter}>
      <div css={tradeFreqValueCss}>{state.frequency?.tradingNumFrequency}</div>
    </div>
  )
})

export default { Lot: TradeFrequencyLot, Num: TradeFrequencyNum }

import React, { memo, useMemo } from 'react'
import { css } from '@emotion/react'
import { Paper } from '@mui/material'
import {
  Tooltip,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
} from 'recharts-new'
import { RadarData } from '~/modules/radar-chart/RadarChartDataType'
import { BLACK, globalGrey, WHITE, globalYellow } from '~/modules/AppLayout/Colors'

const CustomizedRadarChart = memo<
  React.PropsWithChildren<{
    details: RadarData[]

    showRating?: boolean
    fontSize?: number
  }>
>(function CustomizedRadarChart(props) {
  // 每種指標名稱label
  const CustomizedAngleLabel = (tickProps: {
    x: number
    y: number
    payload: { value: string; index: number }
  }) => {
    const { x, y, payload } = tickProps
    const adjustLabel = payload.value.replace('/', '')
    const lines = adjustLabel.split(',')
    const targetDatum = data[payload.index]
    const startCount = Math.round(targetDatum.adjustValue / 20)
    const starProps = {
      fontSize: 25,
      y: lines.length === 1 ? y + 14 : lines.length === 2 ? y + 28 : y + 42,
      textAnchor: 'start',
      fill: globalYellow.y700,
    }
    const wordSize = props.fontSize ?? 16
    return (
      <g>
        <text fontSize={wordSize} y={y} x={x} textAnchor='middle' fill={BLACK}>
          {lines[0]}
        </text>
        {lines[1] && (
          <text fontSize={wordSize} y={y} x={x} textAnchor='middle' fill={BLACK}>
            {lines[1]}
          </text>
        )}
        {lines[2] && (
          <text fontSize={wordSize} y={y} x={x} textAnchor='middle' fill={BLACK}>
            {lines[2]}
          </text>
        )}

        {props.showRating ? (
          <>
            {startCount >= 1 && (
              <text {...starProps} textAnchor='middle' x={x - 40} y={y + 20}>
                &#9733;
              </text>
            )}
            {startCount >= 2 && (
              <text {...starProps} textAnchor='middle' x={x - 20} y={y + 20}>
                &#9733;
              </text>
            )}
            {startCount >= 3 && (
              <text {...starProps} textAnchor='middle' x={x + 0} y={y + 20}>
                &#9733;
              </text>
            )}
            {startCount >= 4 && (
              <text {...starProps} textAnchor='middle' x={x + 20} y={y + 20}>
                &#9733;
              </text>
            )}
            {startCount >= 5 && (
              <text {...starProps} textAnchor='middle' x={x + 40} y={y + 20}>
                &#9733;
              </text>
            )}
          </>
        ) : null}
      </g>
    )
  }

  const data = useMemo(() => {
    return props.details.map(datum => ({
      subject: datum.label,
      value: datum.value,
      fullMark: 100,
      adjustValue: datum.adjustValue,
      comparedAdjustValue: datum.comparedAdjustValue,
    }))
  }, [props.details])

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <RadarChart data={data}>
        <PolarGrid />
        <Tooltip content={<CustomizedTooltip />} />
        <Radar
          dataKey='comparedAdjustValue'
          stroke={globalGrey.g500}
          fill={globalGrey.g400}
          fillOpacity={0.5}
        />
        <Radar dataKey='adjustValue' stroke={'#1199dd'} fill={'#55aaff'} fillOpacity={0.5} />
        <PolarAngleAxis
          tickSize={30}
          tickLine={false}
          stroke={globalGrey.g500}
          dataKey='subject'
          tick={(p: any) => CustomizedAngleLabel(p)}
        />
      </RadarChart>
    </ResponsiveContainer>
  )
})

const CustomizedTooltip = ({ payload }: { payload?: any[] }) => {
  // 只會顯示有合理化在圖上的欄位，所以拿tradeValueKey比對去拿Datum物件
  if (!payload || (payload && payload.length < 1)) return null
  const datum = payload[0].payload
  const adjustSubject = datum.subject.split(',').slice(0, 2).join('')
  return (
    <Paper
      css={css`
        background: ${WHITE};
        padding: 8px;
        border-radius: 8px;
        font-size: 16px;
        border: ${globalGrey.g500} 1px solid;
        & > p {
          margin: 4px 0;
        }
      `}
    >
      <p>{adjustSubject}</p>
      <p>{datum.value}</p>
    </Paper>
  )
}

export default CustomizedRadarChart

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
  jc,
} from '~/modules/AppLayout/FlexGridCss'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'
import {
  emptyDataAvergeValue,
  emptyTradeInfo,
  HuananAverage,
  HuananTradeInfo,
  useHuananAvergeValue,
  useHuananTradeInfo,
} from './MaRatio'

const trendCheck = (midValue: number, horseValue: number, closeValue: number) => {
  if (closeValue > midValue && midValue > horseValue) {
    return '支撐'
  } else if (closeValue < midValue && midValue < horseValue) {
    return '壓力'
  } else return '無狀態'
}

const Title = memo<ReactProps>(function Title() {
  return (
    <styleds.row
      css={css`
        height: 34px;
        background-color: #343434;
      `}
    >
      <p>週期</p>
      <p>差值</p>
      <p>狀態</p>
    </styleds.row>
  )
})

const Item = memo<ReactProps<{ data: HuananAverage; close: number }>>(function Item(props) {
  const interval = props.data.interval.replace('minutes', '')
  const midValue = props.data.midValue
  const horseValue = props.data.horseValue

  const long = props.close > midValue && midValue > horseValue
  const short = props.close < midValue && midValue < horseValue

  const diff = midValue - horseValue
  const trend = trendCheck(midValue, horseValue, props.close)

  const valueFill = () => {
    if (long) {
      return '#ff3333'
    } else if (short) {
      return '#33bb33'
    } else return '#eeeeee'
  }

  return (
    <styleds.row
      css={css`
        height: 32px;
        background-color: #252525;
      `}
    >
      <p>{interval}</p>
      <p>{diff}</p>
      <p
        css={css`
          color: ${valueFill()};
        `}
      >
        {trend}
      </p>
    </styleds.row>
  )
})

const Position = memo<
  ReactProps<{
    longTrend: boolean
    shortTrend: boolean
    midValue: number
    close: number
    info: HuananTradeInfo[]
  }>
>(function Position(props) {
  const close = props.close
  const infoSignal = props.info.map(s => s.position)[0]
  const buyCondition = infoSignal === 1
  const shortCondition = infoSignal == -1
  const midValue = infoSignal === 0 ? ' - ' : props.info.map(s => s.entryPrice)[0]

  const backgroundFill = buyCondition ? '#775555' : shortCondition ? '#557755' : '#454545'
  const signalFill = buyCondition ? '#ee4444' : shortCondition ? '#44ee44' : '#878787'
  const signal = buyCondition ? '機會' : shortCondition ? '機會' : '等待'

  return (
    <styleds.positionItem backgroundFill={backgroundFill}>
      <span>現價:{close}</span>
      <span>高勝價:{midValue}</span>
      <styleds.positionSignalTip backgroundFill={signalFill}>{signal}</styleds.positionSignalTip>
    </styleds.positionItem>
  )
})

export const MaTrend = memo<ReactProps>(function MaTrend() {
  useEffect(() => {
    signalrStore2.addQuote(['TX-1'])
    return () => {
      signalrStore2.removeQuote(['TX-1'])
    }
  }, [JSON.stringify(['TX-1'])])

  const value = useSnapshot(signalrStore2.values.quote)['TX-1']

  const close = value?.close ?? 0
  const high = value?.high ?? 0
  const low = value?.low ?? 0
  const intradeDay = useSnapshot(staticStore.tradedDate.intraday)
  const data = useHuananAvergeValue(intradeDay.format('YYYY-MM-DD')).data ?? emptyDataAvergeValue
  const info = useHuananTradeInfo(intradeDay.format('YYYY-MM-DD')).data ?? emptyTradeInfo

  const mid_60 = data?.filter(s => s.interval === 'minutes60')[0]?.midValue
  const mid_30 = data?.filter(s => s.interval === 'minutes30')[0]?.midValue
  const mid_15 = data?.filter(s => s.interval === 'minutes15')[0]?.midValue
  const mid_5 = data?.filter(s => s.interval === 'minutes5')[0]?.midValue
  const mid_3 = data?.filter(s => s.interval === 'minutes3')[0]?.midValue
  const mid_1 = data?.filter(s => s.interval === 'minutes1')[0]?.midValue

  const horse_60 = data?.filter(s => s.interval === 'minutes60')[0]?.horseValue
  const horse_30 = data?.filter(s => s.interval === 'minutes30')[0]?.horseValue
  const horse_15 = data?.filter(s => s.interval === 'minutes15')[0]?.horseValue
  const horse_5 = data?.filter(s => s.interval === 'minutes5')[0]?.horseValue
  const horse_3 = data?.filter(s => s.interval === 'minutes3')[0]?.horseValue
  const horse_1 = data?.filter(s => s.interval === 'minutes1')[0]?.horseValue

  const longTrend60 = close > mid_60 && mid_60 > horse_60
  const longTrend30 = close > mid_30 && mid_30 > horse_30
  const longTrend15 = close > mid_15 && mid_15 > horse_15
  const longTrend5 = close > mid_5 && mid_5 > horse_5
  const longTrend3 = close > mid_3 && mid_3 > horse_3
  //多排 + 收盤>中線 + 當根低價<中線(回測)
  //now: 收盤<中線 + 多排
  const longTrend1 = close < mid_1 && mid_1 > horse_1 //&& low < mid_1

  const shortTrend60 = close < mid_60 && mid_60 < horse_60
  const shortTrend30 = close < mid_30 && mid_30 < horse_30
  const shortTrend15 = close < mid_15 && mid_15 < horse_15
  const shortTrend5 = close < mid_5 && mid_5 < horse_5
  const shortTrend3 = close < mid_3 && mid_3 < horse_3
  //空排 + 收盤<中線 + 當根高價>中線(回測)
  //now: 收盤>中線 + 空排
  const shortTrend1 = close > mid_1 && mid_1 < horse_1 //&& high > mid_1

  const longTrend =
    longTrend60 && longTrend30 && longTrend15 && longTrend5 && longTrend3 && longTrend1
  const shortTrend =
    shortTrend60 && shortTrend30 && shortTrend15 && shortTrend5 && shortTrend3 && shortTrend1

  console.log({ close: close, ma1_mid: mid_1, ma1_horse: horse_1 })
  return (
    <styleds.container>
      <Title />
      <styleds.itemContent>
        <Item
          data={data.filter(s => s.interval === 'minutes60')[0] ?? emptyDataAvergeValue[0]}
          close={close}
        />
        <Item
          data={data.filter(s => s.interval === 'minutes30')[0] ?? emptyDataAvergeValue[0]}
          close={close}
        />
        <Item
          data={data.filter(s => s.interval === 'minutes15')[0] ?? emptyDataAvergeValue[0]}
          close={close}
        />
        <Item
          data={data.filter(s => s.interval === 'minutes5')[0] ?? emptyDataAvergeValue[0]}
          close={close}
        />
        <Item
          data={data.filter(s => s.interval === 'minutes3')[0] ?? emptyDataAvergeValue[0]}
          close={close}
        />
        <Item
          data={data.filter(s => s.interval === 'minutes1')[0] ?? emptyDataAvergeValue[0]}
          close={close}
        />
      </styleds.itemContent>
      <Position
        info={info}
        longTrend={longTrend}
        shortTrend={shortTrend}
        midValue={mid_1}
        close={close}
      />
      <styleds.updateInfo>
        <p>更新時間:</p>
        <p>{data.filter(s => s.interval === 'dateTime').map(s => s.midValue)}</p>
        <p>{info.map(s =>s.updateTime)[0]}</p>
      </styleds.updateInfo>
    </styleds.container>
  )
})

const styleds = {
  row: styled.div`
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 30% 35% 35%;
    border-radius: 4px;
    padding: 0px 4px;
    font-size: 14px;
    & > p {
      display: flex;
      margin: 0px;
      justify-content: center;
      align-items: center;
    }
  `,
  //
  positionItem: styled.div<{ backgroundFill: string }>`
    ${fill_horizontal_all_center};
    ${jc.spaceAround};
    height: 36px;
    background-color: ${props => props.backgroundFill};
    font-size: 14px;
    border-radius: 5px;
  `,
  positionSignalTip: styled.div<{ backgroundFill: string }>`
    padding: 0px 4px;
    border-radius: 3px;
    color: #ffffff;
    background-color: ${props => props.backgroundFill};
  `,
  //
  container: styled.div`
    ${fill_vertical_cross_center};
    height: 310px;
    padding: 2px;
    gap: 4px;
  `,
  itemContent: styled.div`
    ${fill_vertical_cross_center};
    height: 206px;
    gap: 4px;
  `,
  updateInfo: styled.div`
    ${fill_horizontal_cross_center};
    justify-content: end;
    gap: 8px;
    height: 16px;
    & > p {
      margin: 0;
      font-size: 10px;
    }
  `,
}

import React, { memo } from 'react'
import { useTradingSummaryHashState } from '~/modules/tradeSheet/tradingAnalyzer/useTradingSummaryHashState'
import {
  top3KeyValueCss,
  top3KeyCss,
  top3ValueCss,
} from '~/modules/tradeSheet/tradingAnalyzer/tradingAnalyzerCss'

const TopThreeProfit = memo(function TopThreeProfit(props) {
  const {
    state: { top3Pnl },
  } = useTradingSummaryHashState.useContainer()
  return (
    <>
      {top3Pnl?.profitStock1 ? (
        <div css={top3KeyValueCss}>
          <p css={top3KeyCss}>{top3Pnl?.profitStock1.commodity}</p>
          <p css={top3ValueCss}>$ {USPriceFormat(top3Pnl?.profitStock1.totalProfit)}</p>
        </div>
      ) : (
        <Empty />
      )}
      {top3Pnl?.profitStock2 ? (
        <div css={top3KeyValueCss}>
          <p css={top3KeyCss}>{top3Pnl?.profitStock2.commodity}</p>
          <p css={top3ValueCss}>$ {USPriceFormat(top3Pnl?.profitStock2.totalProfit)}</p>
        </div>
      ) : (
        <Empty />
      )}
      {top3Pnl?.profitStock3 ? (
        <div css={top3KeyValueCss}>
          <p css={top3KeyCss}>{top3Pnl?.profitStock3.commodity}</p>
          <p css={top3ValueCss}>$ {USPriceFormat(top3Pnl?.profitStock3.totalProfit)}</p>
        </div>
      ) : (
        <Empty />
      )}
    </>
  )
})

const TopThreeLoss = memo(function TopThreeLoss() {
  const {
    state: { top3Pnl },
  } = useTradingSummaryHashState.useContainer()

  return (
    <>
      {top3Pnl?.lossStock1 ? (
        <div css={top3KeyValueCss}>
          {' '}
          <p css={top3KeyCss}>{top3Pnl?.lossStock1.commodity}</p>
          <p css={top3ValueCss}>$ {USPriceFormat(top3Pnl?.lossStock1.totalProfit)}</p>
        </div>
      ) : (
        <Empty />
      )}

      {top3Pnl?.lossStock2 ? (
        <div css={top3KeyValueCss}>
          <p css={top3KeyCss}>{top3Pnl?.lossStock2.commodity}</p>
          <p css={top3ValueCss}>$ {USPriceFormat(top3Pnl?.lossStock2.totalProfit)}</p>
        </div>
      ) : (
        <Empty />
      )}
      {top3Pnl?.lossStock3 ? (
        <div css={top3KeyValueCss}>
          <p css={top3KeyCss}>{top3Pnl?.lossStock3.commodity}</p>
          <p css={top3ValueCss}>$ {USPriceFormat(top3Pnl?.lossStock3.totalProfit)}</p>
        </div>
      ) : (
        <Empty />
      )}
    </>
  )
})

const Empty = memo(function Empty() {
  return <div css={top3KeyValueCss}>-</div>
})

const USPriceFormat = (n: number) => {
  const isNegative = n < 0
  const strNum = Math.abs(n).toString()
  const numberNFloat = strNum.split('.')
  const reversedN = numberNFloat[0].split('').reverse()
  const reverseReversedN = reversedN
    .map((charNum, index) =>
      index !== reversedN.length - 1 && (index + 1) % 3 === 0 ? `,${charNum}` : charNum,
    )
    .reverse()
    .join('')
  return `${isNegative ? '-' : ''}${reverseReversedN}${
    numberNFloat[1] ? /*`.${numberNFloat[1]}`*/ '' : ''
  }`
}

export default { Profit: TopThreeProfit, Loss: TopThreeLoss }

import { memo } from 'react'
import { scrollbar2Css } from '~/css/scrollbarCss'
import { OverViewTable } from '~/pages/futuresai/daily-chips/component/OverViewTable'

export const ChipsOverView = memo<ReactProps>(function ChipsOverView() {
  return (
    <div css={scrollbar2Css}>
      <OverViewTable />
    </div>
  )
})

import { memo } from 'react'
import { useTradingSummaryHashState } from '~/modules/tradeSheet/tradingAnalyzer/useTradingSummaryHashState'
import { totalProfitCss } from '~/modules/tradeSheet/tradingAnalyzer/tradingAnalyzerCss'

const TotalProfit = memo(function TotalProfit() {
  const { state } = useTradingSummaryHashState.useContainer()

  return <div css={totalProfitCss}>{state.profit}</div>
})

export default TotalProfit

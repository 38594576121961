import { useCallback, useState } from 'react'
import { createContainer } from '~/modules/unstated-next-utils/createContainer'
import { RadarData } from '~/modules/radar-chart/RadarChartDataType'
import { PROPORTION_COLORS } from '~/modules/tradeSheet/shared/propotionColors'
import { useTimeRangeStore } from '~/modules/tradeSheet/shared/useTimeRangeStore'
import {
  useWinRate,
  useFrequency,
  useCommodityProportion,
  useMaxDrawdown,
  useProfit,
  useProfitFactor,
  useProfitFactorPerLot,
  useDailyProfit,
  useDailyAccumulatedProfit,
  useTop3Pnl,
} from '~/modules/tradeSheet/tradingAnalyzer/useTradingSummaryHashResource'

const useTradingSummaryHash = () => {
  const [hash, setHash] = useState<string>('')
  const since = useTimeRangeStore(state => state.since)

  const handleHash = useCallback((returnValue: string) => {
    setHash(returnValue)
  }, [])

  const winRate = useWinRate(encodeURI(hash), since)
  const profitFactor = useProfitFactor(encodeURI(hash), since)
  const profitFactorPerLot = useProfitFactorPerLot(encodeURI(hash), since)
  const maxDrawdown = useMaxDrawdown(encodeURI(hash), since)
  const profit = useProfit(encodeURI(hash), since)
  const dailyProfit = useDailyProfit(encodeURI(hash), since)
  const accumulatedProfit = useDailyAccumulatedProfit(encodeURI(hash), since)
  const frequency = useFrequency(encodeURI(hash), since)
  const proportion = useCommodityProportion(encodeURI(hash), since)
  const top3Pnl = useTop3Pnl(encodeURI(hash), since)

  const analysisUIDetails: RadarData[] = [
    {
      label: '勝率(%)',
      header: '勝率（獲利交易次數 / 總交易次數）',
      value: (winRate?.winRate ?? 0) * 100,
      adjustValue: winRate?.winRate ?? 0,
      isPercentage: true,
    },
    {
      label: '每口賺賠比',
      header: '每口賺賠比(平均每口平倉獲利/平均每口平倉虧損)',
      value: profitFactorPerLot?.profitFactor ?? 0,
      adjustValue: (profitFactorPerLot?.profitFactor ?? 0) / 2,
    },

    {
      label: '賺賠比',
      header: '賺賠比(平倉獲利累計金額/平倉虧損累計金額)',
      value: profitFactor?.profitFactor ?? 0,
      adjustValue: (profitFactor?.profitFactor ?? 0) / 2,
    },
    /*{
      label: '最大回撤金額',
      header: '最大回撤金額(期間權益淨值損益率拉回之最大金額)',
      value: maxDrawdown?.maxDrawdown ?? 0,
      adjustValue: (maxDrawdown?.maxDrawdown ?? 0) / 2000,
      isPercentage: false,
    },*/
  ]

  const propotionUIData = proportion
    .map((p, i) => ({
      name: p.commodity,
      value: p.proportion,
      color: PROPORTION_COLORS[i],
    }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 10)

  return {
    state: {
      /** randomly pick two data */
      isDataReady: winRate && accumulatedProfit ? true : false,
      profit: profit?.profit,
      maxDrawdown: maxDrawdown?.maxDrawdown,
      dailyProfit: dailyProfit?.dailyProfit,
      accumulatedProfit: accumulatedProfit?.dailyProfit,
      frequency,
      proportion,
      analysisUIDetails,
      propotionUIData,
      top3Pnl,
    },
    acts: {
      handleHash,
    },
  }
}

export const useTradingSummaryHashState = createContainer(useTradingSummaryHash)

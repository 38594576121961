import { css } from '@emotion/react'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'
import { InformationForm } from '~/modules/symbolQuote/information-form'
import IntradayTrendChart from '~/modules/trendChart'
import { store } from '../heineken_template/_private/store'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import SymbolRankingContent from '~/modules/investment-consultant/symbolList/body/SymbolCardRankingBody'
import SymbolRankingHeader from '~/modules/investment-consultant/symbolList/header/SymbolRankingHeader'

const symbols = ['TX-1', 'MTX-1', 'NQ-1', 'CL-1', 'NG-1']

export const David0705_SidePane1 = memo<ReactProps>(function David0705_SidePane1() {
  const charting = useSnapshot(store.charting)

  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        width: 336px;
        padding: 4px;
        background-color: #161616cc;
      `}
    >
      <TitleSymbolQuote.Default symbol={charting.symbol} />
      <div
        css={css`
          width: 100%;
          height: 172px;
        `}
      >
        <IntradayTrendChart
          symbol={charting.symbol}
          ticksSize={14}
          ticksHeight={20}
          priceTicksMargin={2.5}
          priceTicksSize={14}
        />
      </div>
      <div
        css={css`
          width: 100%;
          height: 156px;
        `}
      >
        <InformationForm symbol={charting.symbol} />
      </div>

      <div
        css={css`
          height: 32px;
          width: 100%;
        `}
      >
        <SymbolRankingHeader.Simple
          countArray={[1, 5, 20, 60]}
          css={css`
            background-color: #ffffff;
            padding: 0 24px;
          `}
        />
      </div>

      <div
        css={css`
          height: 40px;
          width: 100%;
        `}
      >
        <SymbolRankingContent.simple.Display
          symbolNumber={charting.symbol}
          useChart={store.charting}
          countArray={[1, 5, 20, 60]}
        />
      </div>
      <div
        css={css`
          width: 100%;
          height: calc(100% - 426px);
          background-color: #252525;
        `}
      >
        <SimpleQuoteListInteractWithChart
          data={symbols}
          chart={store.charting}
        />
      </div>
    </div>
  )
})
